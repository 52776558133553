var controller = new ScrollMagic.Controller();

if($('.project-main-section').length){
	var quoteTween = TweenMax.fromTo('#quote-01', 0.5, 
		{opacity: 0, scale: 0},
		{opacity: 1, scale: 1});
	var quoteScene = new ScrollMagic.Scene({
		triggerElement: "#trigger-01",
		triggerHook: 0.7,
		reverse:false
	})
	.setTween(quoteTween)
	.addTo(controller);

	var projectListTween = TweenMax.staggerFromTo('.projectlist-item', 0.5, 
		{ top:100, opacity: 0 }, 
		{ top:0, opacity: 1 },
		0.1
		);
	var projectListScene = new ScrollMagic.Scene({
		triggerElement: "#projects-list",
		triggerHook: 0.7,
		reverse: false
	})
	.setTween(projectListTween)
	.addTo(controller);


	var projectIconsTween = TweenMax.staggerFromTo('.work-item', 0.5, 
		{ top:100, opacity: 0 }, 
		{ top:0, opacity: 1 },
		0.1
		);
	var projectIconsScene = new ScrollMagic.Scene({
		triggerElement: "#projects-icons",
		triggerHook: 0.7,
		reverse: false
	})
	.setTween(projectIconsTween)
	.addTo(controller);
}

if($('.offer-listing').length){

	if($('#gray-box').length){
		var grayTween = TweenMax.fromTo('#gray-text', 0.5, 
			{opacity: 0, scale: 0},
			{opacity: 1, scale: 1});
		var grayScene = new ScrollMagic.Scene({
			triggerElement: "#gray-box",
			triggerHook: 0.7,
			reverse:false
		})
		.setTween(grayTween)
		.addTo(controller);
	}
	var offerListTween = TweenMax.staggerFromTo('.offer-item', 0.6, 
		{ top:100, opacity: 0 }, 
		{ top:0, opacity: 1 },
		0.3
		);
	var offerListScene = new ScrollMagic.Scene({
		triggerElement: "#offer-listing",
		triggerHook: 0.7,
		reverse: false
	})
	.setTween(offerListTween)
	.addTo(controller);

	var additionalListTween = TweenMax.staggerFromTo('.additional-item', 1, 
		{ top:100, opacity: 0 }, 
		{ top:0, opacity: 1 },
		0.2
		);
	var additionalListScene = new ScrollMagic.Scene({
		triggerElement: "#offer-training",
		triggerHook: 0.7,
		reverse: false
	})
	.setTween(additionalListTween)
	.addTo(controller);
}

if($('.about-quote').length){

	var leftPersonTween = TweenMax.fromTo('#person-left', 0.5, 
		{opacity: 0.7, left: '-300px' },
		{opacity: 1, left: 0 });
	var leftPersonScene = new ScrollMagic.Scene({
		triggerElement: "#about-trigger",
		triggerHook: 0.7,
		reverse:false
	})
	.setTween(leftPersonTween)
	.addTo(controller);

	var rightPersonTween = TweenMax.fromTo('#person-right', 0.5, 
		{opacity: 0.7, left: '300px' },
		{opacity: 1, left: 0 });
	var rightPersonScene = new ScrollMagic.Scene({
		triggerElement: "#about-trigger",
		triggerHook: 0.7,
		reverse:false
	})
	.setTween(rightPersonTween)
	.addTo(controller);


	var quoteTween = TweenMax.fromTo('#quote-01', 0.5, 
		{opacity: 0, scale: 0},
		{opacity: 1, scale: 1});
	var quoteScene = new ScrollMagic.Scene({
		triggerElement: "#trigger-01",
		triggerHook: 0.7,
		reverse:false
	})
	.setTween(quoteTween)
	.addTo(controller);
}